import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Alert, Col, Card, Row } from "reactstrap";
import LayoutAll from "./layout/LayoutAll";
import axios from "axios";
import "./styles/style.css";
import Loading from "./Pages/Loading";
import QRcode from "./assets/images/QR_code.png";
import atolin from "./assets/images/atolin.png";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { stranka } = useParams();

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/PagesData/kontakt`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [stranka]);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (error) {
    return <Alert color="danger">Při získávání dat došlo k chybě.</Alert>;
  } else if (response) {
    return (
      <>
        <LayoutAll>
          <section className="introAll plain bg-white">
            <div className="inner-content container">
              <Col>
                <Row>
                  <UnsafeComponent html={response.text} />
                </Row>
              </Col>
              <Col>
                <Row>
                  <p>
                    <br></br>
                  </p>
                  <Card
                    body
                    className="border-radius shadow-sm rounded text-center"
                  >
                    <iframe
                      src="https://frame.mapy.cz/s/nebobufega"
                      width="100%"
                      height="400"
                      frameborder="0"
                      title="maps"
                    ></iframe>
                  </Card>
                </Row>
              </Col>
              <Row>
                <Col>
                  <h2>Platba přes QR kód</h2>
                  <p>QR kód pro platby na účet Paraklub Liberec z.s.</p>
                  <p>
                    <b>Do poznámky vždy uvádějte účel platby.</b>
                  </p>
                  <img src={QRcode} className="qr_code" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <img src={atolin} className="atolin" />
                </Col>
              </Row>
            </div>
          </section>
        </LayoutAll>
      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default Contact;
